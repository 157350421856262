// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-albumdownload-js": () => import("./../../../src/pages/albumdownload.js" /* webpackChunkName: "component---src-pages-albumdownload-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-merch-js": () => import("./../../../src/pages/merch.js" /* webpackChunkName: "component---src-pages-merch-js" */),
  "component---src-pages-pravna-obvestila-js": () => import("./../../../src/pages/pravna-obvestila.js" /* webpackChunkName: "component---src-pages-pravna-obvestila-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-single-image-template-js": () => import("./../../../src/templates/single-image-template.js" /* webpackChunkName: "component---src-templates-single-image-template-js" */)
}

